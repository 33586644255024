import React from 'react'
import PropTypes from "prop-types";
import { FacebookShareButton } from "react-share";

const ShareButton = ({url , colour , iconColour}) => {
    const socialNetwork = () => {
        if(document.querySelector('.react-share__ShareButton')){
            document.querySelector('.react-share__ShareButton').click()
        }
    }
    return (
        <>
            <FacebookShareButton url={`https://cultural.upc.edu.pe/${url}`}/>
            <button className="SocialIconSubContainer button-float-share" style={{background:colour}} onClick={socialNetwork}>
                <i className="fa fa-facebook" style={{color: iconColour,borderColor:iconColour}} aria-hidden="true"></i>
            </button>
        </>
    )
}

ShareButton.propTypes = {
    url: PropTypes.string.isRequired,
    colour: PropTypes.string.isRequired
}
ShareButton.defaultProps = {
    colour: 'rgb(227, 30, 17)',
    iconColour: '#fff'
}

export default ShareButton
