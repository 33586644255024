import React from 'react';
import Header from "../Header/upcc";
import "../../styles/main.css"
import "../../styles/responsive.css"
import "../../styles/colorysentimiento.css"
import "../../styles/colorysentimientoresponsive.css"
import Seo from "./SEO";
import "../../styles/template3.css"
import "../../styles/template3part2.css"
import "../../styles/shared.css"

const Layout = ({ children,
  descritpion = "+Arte y Cultura",
  imgurl = "=//cultural.upc.edu.pe/static/UpcCultural_Share-a1f8e23f2b58627c09d19c7bfc611366.jpg",
  subtitile = "+Arte y Cultura",
  title = "Bienvenidos a UPC Cultural",
  metaTitle = "Bienvenidos a UPC Cultural",
  metaKeywords = "UPC,Perú,Arte y cultura,Artes audiovisuales,Artes escénicas,Artes plásticas,Creación literaria,Diseño y artes visuales,Música y creación sonora,Patrimonio,Agenda Cultural",
  metaDescripcin = "Visita y descubre experiencias, eventos y noticias de contenido artístico y cultural.",
}) => {
  return (
    <>
      <Seo
        descritpion={descritpion}
        imgurl={imgurl}
        subtitile={subtitile}
        title={title}
        metaTitle={metaTitle}
        metaKeywords={metaKeywords}
        metaDescripcin={metaDescripcin}
      />
      <Header />

      {children}
    </>
  )
}

export default Layout
