import React from "react"
import Layout from "../share/layout"
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import arrow_gold_back from '../../images/arrow_gold.png'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useLocation } from "@reach/router"
import ControlHomePREV from '../../images/arrow_prev.png'
import ControlHomeNEXT from '../../images/arrow_next.png'
import { Link } from 'gatsby'
import Image from 'gatsby-image';
import ShareButton from "../share/ShareButton";


const Exposition3slider = ({ pageContext }) => {
    
    let location = useLocation();
    const QueryResultData = pageContext.datacarrusel;

    var normalize = (function() {
        var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç,", 
        to   = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
        mapping = {};
        
        for(var i = 0, j = from.length; i < j; i++ )
        mapping[ from.charAt( i ) ] = to.charAt( i );
        
        return function( str ) {
            var ret = [];
            for( var i = 0, j = str.length; i < j; i++ ) {
                var c = str.charAt( i );
                if( mapping.hasOwnProperty( str.charAt( i ) ) )
                ret.push( mapping[ c ] );
                else if ((str[i]===" "))
                ret.push( '-' );
                else if ((str.length-1 === i) && (str[str.length-1]===" "))
                ret.push( '-' );
                else
                ret.push( c );
            }      
            return ret.join( '' );
        }
    })();
    
    const clickSliderPREV = () => {
        document.querySelector('.control-prev').click()
    }
    const clickSliderNEXT = () => {
        document.querySelector('.control-next').click()
    }
  return(
    <Layout 
        descritpion={QueryResultData} 
        title={QueryResultData.titulo === '-' ? QueryResultData.subtitulo : QueryResultData.titulo } 
        subtitile={QueryResultData.subtitulo === '-' ? QueryResultData.titulo :  QueryResultData.subtitulo} 
        imgurl={QueryResultData.ImagesCarruselBackground[0].file.url}
        metaTitle={QueryResultData.metaTitle}
        metaKeywords={QueryResultData.metaKeywords}
        metaDescripcin={QueryResultData.metaDescripcin}
        
    >
        <div className="exposicion-slider-container template-3 template-type-1">
            <ShareButton url={location.pathname} colour={"rgb(200, 165, 60)"}/>
            <div className="button-back">
                <Link to={`/galeria/${normalize(QueryResultData.idParaEnlazar).toLocaleLowerCase().trim()}`}><button>
                    <img alt="imagen de retroceso" src={arrow_gold_back}/>
                    <p>VOLVER AL INICIO</p>
                </button></Link>
            </div>
            {QueryResultData.ImagesCarruselBackground?.length > 1 &&
                <React.Fragment>
                    <button onClick={clickSliderPREV} className="button-control-prev"><img alt="flecha anterior" className="button-control-prev-icon" src={ControlHomePREV}/></button>
                    <button onClick={clickSliderNEXT} className="button-control-next"><img alt="flecha siguiente" className="button-control-next-icon" src={ControlHomeNEXT}/></button>
                </React.Fragment>
            }                    
            { QueryResultData.ImagesCarruselBackground?.length === 1 ?
                <div className="single-image">
                    <div className="img-exposion-gradient-container"></div>
                    <Image className="img-exposicion-container" fluid={QueryResultData.ImagesCarruselBackground[0].fluid} alt={QueryResultData.ImagesCarruselBackground[0].description ? QueryResultData.ImagesCarruselBackground[0].description : QueryResultData.ImagesCarruselBackground[0].title}/>
                    <div className="carousel-caption">
                        <div className="carousel-caption-text-container slider-caption-3">
                            <div className="carousel-caption-tittle-container slider-type-3">
                                <div>
                                    {QueryResultData.titulo !== '-' &&
                                        <h1 className="title-carusel-3" style={{color: '#C8A53C !important'}}>{QueryResultData.titulo}</h1>
                                    }
                                    {QueryResultData.subtitulo !== '-' &&
                                        <h2 className="subtitle-carusel-3" style={{color: '#C8A53C !important'}}>{QueryResultData.subtitulo}</h2>
                                    }
                                </div>
                            </div>
                            <div className="vertical_line"></div>
                            <div className="carousel-caption-subtittle-container carrusel-3">{documentToReactComponents(QueryResultData.primeraDescripcion.json)}</div>
                        </div>
                    </div>
                </div>
                :
                <Carousel className="carrusel-tipo-3" autoPlay infiniteLoop interval={8000} transitionTime={1000} stopOnHover={false} showThumbs={false}>
                    {
                    QueryResultData.ImagesCarruselBackground?.map((CarruselImage,i) => (
                    <div key={i}>
                        <div className="img-exposion-gradient-container"></div>
                        <Image className="img-exposicion-container" fluid={CarruselImage.fluid} alt={CarruselImage.description ? CarruselImage.description : CarruselImage.title}/>
                        <div className="carousel-caption">
                            <div className="carousel-caption-text-container slider-caption-3">
                                <div className="carousel-caption-tittle-container slider-type-3">
                                    <div>
                                        {QueryResultData.titulo !== '-' &&
                                            <h1 className="title-carusel-3" style={{color: '#C8A53C !important'}}>{QueryResultData.titulo}</h1>
                                        }
                                        {QueryResultData.subtitulo !== '-' &&
                                            <h2 className="subtitle-carusel-3" style={{color: '#C8A53C !important'}}>{QueryResultData.subtitulo}</h2>
                                        }
                                    </div>
                                </div>
                                <div className="vertical_line"></div>
                                {i === 0 && <div className="carousel-caption-subtittle-container carrusel-3">{documentToReactComponents(QueryResultData.primeraDescripcion.json)}</div>}
                                {i === 1 && <div className="carousel-caption-subtittle-container carrusel-3">{documentToReactComponents(QueryResultData.segundaDescripcion.json)}</div>}
                                {i === 2 && <div className="carousel-caption-subtittle-container carrusel-3">{documentToReactComponents(QueryResultData.terceraDescripcion.json)}</div>}
                                {i === 3 && <div className="carousel-caption-subtittle-container carrusel-3">{documentToReactComponents(QueryResultData.cuartaDescripcion.json)}</div>}
                                {i === 4 && <div className="carousel-caption-subtittle-container carrusel-3">{documentToReactComponents(QueryResultData.quintaDescripcion.json)}</div>}
                                {i === 5 && <div className="carousel-caption-subtittle-container carrusel-3">{documentToReactComponents(QueryResultData.sextaDescripcion.json)}</div>}
                                {i === 6 && <div className="carousel-caption-subtittle-container carrusel-3">{documentToReactComponents(QueryResultData.septimaDescripcion.json)}</div>}
                                {i === 7 && <div className="carousel-caption-subtittle-container carrusel-3">{documentToReactComponents(QueryResultData.octavaDescripcion.json)}</div>}
                                {i === 8 && <div className="carousel-caption-subtittle-container carrusel-3">{documentToReactComponents(QueryResultData.novenaDescripcion.json)}</div>}
                                {i === 9 && <div className="carousel-caption-subtittle-container carrusel-3">{documentToReactComponents(QueryResultData.decimaDescripcion.json)}</div>}
                                {i === 10 && <div className="carousel-caption-subtittle-container carrusel-3">{documentToReactComponents(QueryResultData.onceavaDescripcion.json)}</div>}
                            </div>
                        </div>
                    </div>
                    ))
                    }
                </Carousel>
            }
        </div>
    </Layout>
  )
}
export default Exposition3slider



